<template>
  <v-stepper v-model="currentStep">

    <v-dialog v-model="dialog" fullscreen hide-overlay>
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs6 sm6 md6 lg6>
                  <v-text-field 
                    ref="fullName"
                    v-model="member.fullName" 
                    :label="$t(fullNameLabel)"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 sm6 md6 lg6>
                  <v-text-field 
                    v-model="member.nationality" 
                    :label="$t(nationalityLabel)"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex xs6 sm6 md6 lg6>
                  <v-text-field 
                    v-model="member.idNumber" 
                    :label="$t(idNumberLabel)"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 sm6 md6 lg6>
                  <v-text-field 
                    v-model="member.phoneNumber" 
                    :label="$t(phoneNumberLabel)"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex xs6 sm6 md6 lg6>
                  <v-menu
                    :close-on-content-click="false"
                    v-model="menuBirthDate"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="member.birthDate"
                        :label="$t(birthDateLabel)"
                        persistent-hint
                        prepend-icon="event"
                        readonly
                        :rules="[rules.required]"
                        required
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="member.birthDate" no-title @input="menuBirthDate = false"></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs6 sm6 md6 lg6>
                  <v-text-field 
                    v-model="member.gender" 
                    :label="$t(genderLabel)"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex xs6 sm6 md6 lg6>
                  <v-text-field 
                    v-model="member.occupation" 
                    :label="$t(occupationLabel)"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 sm6 md6 lg6>
                  <v-text-field 
                    v-model="member.address" 
                    :label="$t(addressLabel)"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-flex>

                <v-flex xs6 sm6 md6 lg6>
                  <v-text-field 
                    v-model="member.prevPlace" 
                    :label="$t(prevPlaceLabel)"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 sm6 md6 lg6>
                  <v-text-field 
                    v-model="member.nextDest" 
                    :label="$t(nextDestLabel)"
                    :rules="[rules.required]"
                    required
                  ></v-text-field>
                </v-flex>

              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeDialog">Close</v-btn>
            <v-btn color="primary" text @click="saveDialog">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    
    <v-stepper-items
      v-touch="{
        left: () => swipe('Left'),
        right: () => swipe('Right'),
      }"
    >
      <v-stepper-content
        v-for="n in steps"
        :key="`${n}-content`"
        :step="n"
      >
        <v-layout row fill-height>
          <v-spacer></v-spacer>
          <v-flex xs5>
            <v-file-input
              @change="faceImagePicked"
              accept="image/*"
              label="Choose Face Image"
              v-model="faceImage"
              prepend-icon="photo_camera"
            ></v-file-input>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs5>
            <v-file-input
              @change="idImagePicked"
              accept="image/*"
              label="Choose ID Image"
              v-model="idImage"
              prepend-icon="photo_camera"
            ></v-file-input>
          </v-flex>
        </v-layout>
        
        <v-layout row fill-height>
          <v-flex xs6>
            <v-container fluid>
              <v-layout row justify-space-between>
                <v-flex xs12>
                  <v-img :src="member.faceImgSrc"></v-img>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
          <v-flex xs6>
            <v-container fluid>
              <v-layout row justify-space-between>
                <v-flex xs12>
                  <v-img :src="member.idImgSrc"></v-img>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>

        <v-layout row fill-height>
          <v-flex xs12>
            <v-btn rounded :color="infoBtnColor" @click="openDialog()" class="headline">{{ $t(infoBtnLabel) }}</v-btn>
          </v-flex>
        </v-layout>

        <v-layout row fill-height>
          <v-toolbar
            slot="footer"
            class="mt-2"
            color="primary"
            dark
            dense
            flat
          >
          <v-flex xs12>
            <v-spacer></v-spacer>
            <v-btn class="headline" icon @click="prevStep(n)">
              <v-icon x-large>navigate_before</v-icon>
            </v-btn>
            <span class="headline">{{ currentStep }} / {{ steps }}</span>
            <v-btn class="headline" icon @click="nextStep(n)">
              <v-icon x-large>navigate_next</v-icon>
            </v-btn>
          </v-flex>
          </v-toolbar>
        </v-layout> 
        
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { Storage } from 'aws-amplify'
import { mapState, mapGetters, mapActions } from 'vuex'
import { CallDialog } from '@/utils'
import store from '@/store'

export default {
  name: 'CheckIn',
  data () {
    return {
      rules: {
        required: value => !!value || 'Value Required',
      },
      infoBtnLabel: 'checkIn.btn_id_info',
      fullNameLabel: 'member.fullName',
      genderLabel: 'member.gender',
      birthDateLabel: 'member.birthDate',
      nationalityLabel: 'member.nationality',
      idNumberLabel: 'member.idNumber',
      occupationLabel: 'member.occupation',
      addressLabel: 'member.address',
      phoneNumberLabel: 'member.phoneNumber',
      prevPlaceLabel: 'member.prevPlace',
      nextDestLabel: 'member.nextDest',
      dialog: false,
      menuBirthDate: false,
      faceImageUrl: null,
      faceImage: null,
      idImageUrl: null,
      idImage: null,
    }
  },
  created () {
    this.setCurrentStep(1)
  },
  async beforeRouteEnter (to, from, next) {
    const error = await store.dispatch('checkIn/loadMembers').catch(error => {
      return error
    })

    if (error) {
      CallDialog(error)
    } else {
      next()
    }
  },
  async beforeRouteUpdate (to, from, next) {
    const error = await store.dispatch('checkIn/loadMembers').catch(error => {      
      return error
    })

    if (error) {
      CallDialog(error)
    } else {
      next()
    }
  },
  computed: {
    infoBtnColor: {
      get() {
        if (this.memberInfoFilled) {
          return 'success'
        } else {
          return 'error'
        }
      }
    },
    currentStep: {
      get() {
        return this.$store.state.checkIn.currentStep
      },
      set (val) {
        return this.setCurrentStep(val)
      }
    },
    ...mapState({
      members: state => state.checkIn.members,
      member: state => state.checkIn.currentMember,
      reservation: state => state.checkIn.reservation
    }),
    ...mapGetters('checkIn', {
      steps: 'steps',
      memberInfoFilled: 'memberInfoFilled',
    })
  },
  methods: {
    async idImagePicked () {

      const blob = await(new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(this.idImage);
      }))

      console.log('idImagePicked', blob)

      await this.putImage({imgBufferArray: blob, isId: true})

      const error = await this.saveMember(true).catch(e => {
        console.error('saveMember', e)
        return e
      })

      if (error) {
        CallDialog(error)
        return
      } 
    },
    async faceImagePicked () {
      // this.faceImageUrl = URL.createObjectURL(this.faceImage)

      const blob = await(new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(this.faceImage);
      }))

      console.log('faceImagePicked', blob)

      await this.putImage({imgBufferArray: blob, isId: false})

      const error = await this.saveMember(true).catch(e => {
        console.error('saveMember', e)
        return e
      })

      if (error) {
        CallDialog(error)
        return
      }
    },
    swipe (direction) {
      if (direction === 'Left') {
        this.goNext()
      } else if (direction === 'Right') {
        this.goPrevious()
      }
    },
    prevStep (n) {
      if (n === 1) {
        this.setCurrentStep(this.steps)

      } else {
        this.setCurrentStep(n - 1)
      }
    },
    nextStep (n) {
      if (n === this.steps) {
        this.setCurrentStep(1)
      } else {
        this.setCurrentStep(n + 1)
      }
    },
    goPrevious () {
      this.prevStep(this.currentStep)
    },
    goNext () {
      this.nextStep(this.currentStep)
    },
    openDialog() {
      this.dialog = true
    },
    closeDialog() {
      console.log('this.$refs', this.$refs)


      const valid = this.$refs.form.validate()

      setTimeout(() => {
        this.dialog = false
      }, 200)
    },
    async saveDialog() {
      console.log('this.$refs', this.$refs)


      const valid = this.$refs.form.validate()

      if (valid) {

        const error = await this.saveMember(false).catch(e => {
          console.error('saveMember', e)
          return e
        })

        if (error) {
          CallDialog(error)
          return
        } 

        // const error2 = await this.verifyMembers(false).catch(e => {
        //   console.error('verifyMembers', e)
        //   return e
        // })

        // if (error2) {
        //   CallDialog(error2)
        //   return
        // } 

        this.dialog = false

        if (this.reservation.isIdVerified) {
          this.$router.push({path: '/guide'})
        } else {
          this.$router.push({path: '/'})
        }


      }
    },
    ...mapActions({
      setCurrentStep: 'checkIn/setCurrentStep',
      saveMember: 'checkIn/saveMember',
      putImage: 'checkIn/putImage',
      loadMembers: 'checkIn/loadMembers',
      verifyMembers: 'checkIn/verifyMembers',
    })
  },
  components: {
  }
}
</script>